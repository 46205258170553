/* WARNING: add only to this file as a last option if styled-components or react helmet doesn't work */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .app {
  height: 100%;
}

@media (min-width: 600px) {
  :root {
    --rsbs-max-w: 412px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}